@import url('https://fonts.googleapis.com/css2?family=Inconsolata:wght@500;700&family=Poppins:wght@300;500;600;800;900&family=Sora:wght@300;400;500;800&family=Space+Mono:wght@400;700&display=swap');

* {
  	margin: 0%;
  	padding: 0%;
}

html, body, #app, #app > div {
	height: 100%;
}

body, html {
  	background: linear-gradient(-45deg, #C9D6FF 25%, rgb(172, 200, 255) 50%, #cfd3ec 75%, #ecfaff 100%);
	background-size: 400% 400%;
	animation: gradient 15s ease infinite;
}

.page {
  	text-align: center;
  	font-family: 'Poppins', sans-serif;
	height: 100%;
}

#agora-img-container > img,
#profile-pic-container > img {
	width: 90%;
  	border-radius: 50%;
}

#agora-img-container,
#profile-pic-container {
	animation: fadeIn .5s ease-out;
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

@keyframes slide {
    from {
        opacity: 0;
        transform: translateX(200px);
    }
	50% {
		opacity: 0.2;
	}
    to {
		opacity: 1;
        transform: translateX(0);
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
		opacity: 1;
    }
}

#nav-bar {
	display: inline-block;
	width: 100%;
	font-weight: bold;
	height: 80px;
	margin-top: 1rem;
}

#nav-bar > * {
	padding: 0 1rem 0 1rem;
	width: fit-content;
	display: inline-block;
	text-decoration: none;
	color: black;
}

#nav-bar a.active,
#nav-bar > *:hover {
	color: white;
	text-shadow: 1px 1px black;
	cursor: pointer;
}

.info-text-container {
	text-align: center;
	margin-left: auto;
	margin-right: auto;
	word-break:normal;
}

.info-text-container > div {
	margin-bottom: 1rem;
	background-color: rgba(255, 255, 255, 0.5);
	border-radius: 10px;
	width: 100%;
	display: grid;
	grid-template-rows: 1fr 1fr;
	justify-items: center;
  	align-items: center;
	animation: slide 0.5s ease-out;
}

.long-text {
	background-color: rgba(255, 255, 255, 0.5);
	text-align: left;
	border-radius: 10px;
	animation: slide 0.5s ease-out;
	padding: 1rem;
}

.long-text > p {
	margin-bottom: 1rem;
}

.big-text {
	font-size: large;
	font-weight: bold;
}

.info-text-container > div > * {
	padding: 0 1rem 0 1rem;
}

.column-container {
	margin-top: 5%;
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
	width: clamp(250px, 70%, 800px);
	height: 100%;
	margin-left: auto;
	margin-right: auto;
	align-items: center;
	justify-content: center;
	overflow-x: hidden;
}

.column-container > div {
	margin-bottom: 1rem;
}

#link {
	font-weight: bold;
	color: black;
	text-decoration: none;
}

#link:hover {
	color: gray;
}

.centered-text {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.project-card {
	background-color: rgba(255, 255, 255, 0.5);
	width: clamp(250px, 30%, 500px);
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 20px;
	padding: 10px;
	border-radius: 50px;
}

.project-card > * {
	margin: 30px;
}

.project-card > p {
	text-align: left;
}

.link-button {
	padding: 20px;
	font-size: larger;
	border-radius: 40px;
	width: 100px;
	border: none;
	background-color: #483248;
	color: white;
	text-decoration: none;
}

.link-button:hover {
	color: #C9D6FF;
	cursor: pointer;
}

.project-image {
	height: 100px;
}